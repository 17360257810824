.container {
    background-color: #fff;
    position: relative;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    @media (max-width: 767px) {
        flex-direction: column;
    }
    @media (min-width: 768px) {
        flex-direction: row;
    }
    
    .description {
        min-height: 185px;
        display: flex;
        align-items: center;
        padding: 0 15px 0 15px;
        @media (max-width: 767px) {
            width: calc(100% - 30px);
        }
        @media (min-width: 768px) {
            width: calc(66.666667% - 30px);
        }

        h1 {
            width: 100%;
            margin: 0;
            @media (max-width: 991px) {
                font: normal 1.3em/190% 'Raleway', sans-serif;
            }

            .mobile-hidden {
                @media (min-width: 768px) {
                    display: none;
                }
            }
        }
    }

    .resume {
        @media (max-width: 767px) {
            width: 100%;
        }
        @media (min-width: 768px) {
            width: 33.333333%;
        }

        a {
            color: #333;
            font: normal 1.4em/200% 'Raleway', sans-serif;
            letter-spacing: -.03em;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 185px;
            background-color: #dddee1;
            transition: ease background-color 0.7s;

            img {
                margin: 0 19px 8px 0;
            }
        }
    }
}
