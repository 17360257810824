.gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .gallery-column {
        display: flex;
        flex-direction: column;
        @media (max-width: 767px) {
            width: 100%;
        }
        @media (min-width: 768px) {
            max-width: 33.333333%;
        }

        a {
            display: contents;

            figure {
                margin: 0;
                display: flex;

                figcaption {
                    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.4) 100%);
                    position: absolute;
                    min-height: 200px;
                    opacity: 0;
                    transition: 0.3s ease-out;
                    text-align: center;
                    padding-top: 35px;
                    color: #fff;
                    font: normal 1.5em/190% 'Raleway', sans-serif;
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                    @media (min-width: 768px) {
                        width: 33.333333%;
                    }
                }

                img {
                    object-fit: contain;
                    height: auto;
                    width: 100%;
                }
            }
    
            &:hover {
                figcaption {
                    padding-top: 25px;
                    opacity: 1;
                    transition: 0.3s ease-out;
                }
            }
        }
    }
}