.motion-container {
    height: 100vh;
}

.video-react-control-bar,
.video-react-big-play-button {
    display: none !important;
}

.bg {
    background-image: radial-gradient(#fdfbfb 0%, #ebedee 100%);  
    background-size: cover;
    height: 99.9%; 
    background-position: center;
    background-repeat: no-repeat;                                                                                                                                                                                                                                                                                                                                                       
}

.video-react {
    position: unset !important;
    padding-top: 0 !important;
}

.video-react-video {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh !important;
    background-position: center center;
    background-size: contain;
    object-fit: cover;
}

.logo {
    background-image: url(../../assets/img/sign-black.svg);
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    position: absolute;
    width: 100%;
    transition: background-position .2s ease;
    @media (max-width: 767px) {
        background-size: 80%;
    }
    @media (min-width: 768px) {
        background-size: 20%;
    }
}

.tag {
    -ms-transform: rotate(-17.5deg);
    -webkit-transform: rotate(-17.5deg);
    transform: rotate(-17.5deg);
}

.background-img {
    background-image: url(../../assets/img/bg-diegotenorio.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100%;
}