@import url(https://fonts.googleapis.com/css?family=Raleway:400,700);
.footer-container{width:100%}.footer-container h1{margin:30px 0 30px 0;text-align:center}.footer-container .footer{width:100%;background-color:#fff;display:flex;justify-content:center}.footer-container .footer ul{display:flex;flex-direction:row;justify-content:center}.footer-container .footer ul a{height:90px;width:100px;display:flex;justify-content:center;align-items:center;transition:background-color 0.3s ease-out}.footer-container .footer ul a:hover{background-color:#eee;transition:background-color 0.3s ease-out}.footer-container .address{display:flex;justify-content:center;padding:15px 0;background-color:#222;color:#fff;font:normal .68em 'Raleway', sans-serif;letter-spacing:1px}

.gallery{display:flex;flex-direction:row;flex-wrap:wrap}.gallery .gallery-column{display:flex;flex-direction:column}@media (max-width: 767px){.gallery .gallery-column{width:100%}}@media (min-width: 768px){.gallery .gallery-column{max-width:33.333333%}}.gallery .gallery-column a{display:contents}.gallery .gallery-column a figure{margin:0;display:flex}.gallery .gallery-column a figure figcaption{background:linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);position:absolute;min-height:200px;opacity:0;transition:0.3s ease-out;text-align:center;padding-top:35px;color:#fff;font:normal 1.5em/190% 'Raleway', sans-serif}@media (max-width: 767px){.gallery .gallery-column a figure figcaption{width:100%}}@media (min-width: 768px){.gallery .gallery-column a figure figcaption{width:33.333333%}}.gallery .gallery-column a figure img{object-fit:contain;height:auto;width:100%}.gallery .gallery-column a:hover figcaption{padding-top:25px;opacity:1;transition:0.3s ease-out}

.motion-container{height:100vh}.video-react-control-bar,.video-react-big-play-button{display:none !important}.bg{background-image:radial-gradient(#fdfbfb 0%, #ebedee 100%);background-size:cover;height:99.9%;background-position:center;background-repeat:no-repeat}.video-react{position:unset !important;padding-top:0 !important}.video-react-video{position:absolute;right:0;bottom:0;top:0;right:0;width:100%;height:100vh !important;background-position:center center;background-size:contain;object-fit:cover}.logo{background-image:url(/static/media/sign-black.7520efea.svg);background-position:center center;background-repeat:no-repeat;height:100%;position:absolute;width:100%;transition:background-position .2s ease}@media (max-width: 767px){.logo{background-size:80%}}@media (min-width: 768px){.logo{background-size:20%}}.tag{-webkit-transform:rotate(-17.5deg);transform:rotate(-17.5deg)}.background-img{background-image:url(/static/media/bg-diegotenorio.2b7ddd34.svg);background-position:center center;background-repeat:no-repeat;background-size:100%;height:100%}

.container{background-color:#fff;position:relative;width:100%;margin-right:auto;margin-left:auto;display:flex}@media (max-width: 767px){.container{flex-direction:column}}@media (min-width: 768px){.container{flex-direction:row}}.container .description{min-height:185px;display:flex;align-items:center;padding:0 15px 0 15px}@media (max-width: 767px){.container .description{width:calc(100% - 30px)}}@media (min-width: 768px){.container .description{width:calc(66.666667% - 30px)}}.container .description h1{width:100%;margin:0}@media (max-width: 991px){.container .description h1{font:normal 1.3em/190% 'Raleway', sans-serif}}@media (min-width: 768px){.container .description h1 .mobile-hidden{display:none}}@media (max-width: 767px){.container .resume{width:100%}}@media (min-width: 768px){.container .resume{width:33.333333%}}.container .resume a{color:#333;font:normal 1.4em/200% 'Raleway', sans-serif;letter-spacing:-.03em;display:flex;flex-direction:row;justify-content:center;align-items:center;height:185px;background-color:#dddee1;transition:ease background-color 0.7s}.container .resume a img{margin:0 19px 8px 0}

html{height:100%;margin:0}body{background-color:#ededf1;height:100%;margin:0;padding:0;color:#5a5a5a}li{list-style:none}h1{font:normal 1.5em/190% 'Raleway', sans-serif;letter-spacing:-.03em}h1::selection,h1::-moz-selection,h2::selection,h2::-moz-selection,h3::selection,h3::-moz-selection,p::selection,p::-moz-selection{background-color:#eeedef;color:#000}p{margin:0}a,a:hover,a:focus,a:active,a:visited{text-decoration:none}::-webkit-scrollbar-track{border-radius:10px}::-webkit-scrollbar{width:12px;background-color:#ddd}::-webkit-scrollbar-thumb{-webkit-border-radius:10px;border:0px solid #ddd;border-radius:4px;background:#bbb}.no-padding{padding:0 !important}.no-margin{margin:0 !important}.text-center{text-align:center !important}

